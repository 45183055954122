<template>
  <BasePageList
    page-name="ИЗПМ: молекулы в трехмерном виде"
    :showed-model-types="modelTypes"
  >
  </BasePageList>
</template>

<script src="./PageParticlesList.ts" lang="ts"/>
<style src="./PageParticlesList.scss" lang="scss"/>
